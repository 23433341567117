import React from "react";

import "../styles/Intro.css";
import Typist from "react-typist";
import "react-typist/dist/Typist.css";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import FadeInSection from "./FadeInSection";
import FractalTree from "./FractalTree";

class Intro extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1",
      visible: true,
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey,
    });
  }
  render() {
    return (
      <div id="intro">
        <FractalTree></FractalTree>
        <Typist avgTypingDelay={120}>
          <span className="intro-title">
            {"hi, "}
            <span className="intro-name">{"Madhav"}</span>
            {" here."}
          </span>
        </Typist>
        <FadeInSection>
          <div className="intro-subtitle">I create stuff sometimes.</div>
          <div className="intro-desc">
            I'm Madhav Rajesh, a passionate and experienced Full Stack Developer
            with 6 years of expertise in building scalable and high-performance
            web applications. Skilled in modern web technologies, including
            React.js, Node.js, and Redux, with a proven track record of
            delivering successful projects in fast-paced environments. Adept at
            optimizing user experiences and integrating complex systems to
            enhance platform functionality. A proactive learner and
            problem-solver, committed to delivering efficient and effective
            solutions.
          </div>
          <a href="mailto:madhav.cs101@gmail.com" className="intro-contact">
            <EmailRoundedIcon></EmailRoundedIcon>
            {" Say hi!"}
          </a>
        </FadeInSection>
      </div>
    );
  }
}

export default Intro;
