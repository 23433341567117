import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FadeInSection from "./FadeInSection";

const isHorizontal = window.innerWidth < 600;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  if (isHorizontal) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  } else {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  if (isHorizontal) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  } else {
    return {
      id: `vertical-tab-${index}`,
    };
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
    display: "flex",
    height: 300,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

const JobList = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const experienceItems = {
    Ringover: {
      jobTitle: "Software Engineer @",
      duration: "JULY 2022 - Present",
      desc: [
        "Developed and optimized a sales prospecting tool using React.js, TypeScript, and Redux, improving multi-channel communication for sales teams.",
        "Integrated GraphQL APIs and collaborated with UI/UX designers to implement pixel-perfect, responsive components.",
        "Deployed backend services using Node.js and AWS, leveraging serverless architecture with AWS Lambda for scalable solutions.",
      ],
    },
    Dyte: {
      jobTitle: "Full Stack Developer @",
      duration: "JULY 2020 - JUNE 2022",
      desc: [
        "Developed and maintained a scalable Unified Live Experience Platform combining audio/video conferencing, live streaming, and chat using React.js and TypeScript.",
        "Collaborated with backend teams to implement GraphQL APIs, improving data handling efficiency.",
        "Deployed on AWS, leveraging EC2 and S3 for scalable computing and storage solutions.",
      ],
    },
    FreshToHome: {
      jobTitle: "Full Stack Developer @",
      duration: "NOV 2018 - MAY 2020",
      desc: [
        "Developed a responsive, user-friendly web application using React.js, Redux, HTML5, and CSS3, improving user engagement and site performance.",
        "Implemented server-side logic using Node.js and Express.js, creating RESTful APIs for seamless communication between frontend and backend systems.",
        "Optimized MySQL database performance through complex SQL queries, indexing, and query optimization, ensuring efficient data storage and retrieval."
      ],
    },    
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation={!isHorizontal ? "vertical" : null}
        variant={isHorizontal ? "fullWidth" : "scrollable"}
        value={value}
        onChange={handleChange}
        className={classes.tabs}
      >
        {Object.keys(experienceItems).map((key, i) => (
          <Tab label={isHorizontal ? `0${i}.` : key} {...a11yProps(i)} />
        ))}
      </Tabs>
      {Object.keys(experienceItems).map((key, i) => (
        <TabPanel value={value} index={i}>
          <span className="joblist-job-title">
            {experienceItems[key]["jobTitle"] + " "}
          </span>
          <span className="joblist-job-company">{key}</span>
          <div className="joblist-duration">
            {experienceItems[key]["duration"]}
          </div>
          <ul className="job-description">
            {experienceItems[key]["desc"].map(function (descItem, i) {
              return (
                <FadeInSection delay={`${i + 1}00ms`}>
                  <li key={i}>{descItem}</li>
                </FadeInSection>
              );
            })}
          </ul>
        </TabPanel>
      ))}
    </div>
  );
};

export default JobList;
