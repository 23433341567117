import React from "react";
import "../styles/About.css";
import FadeInSection from "./FadeInSection";

class About extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1",
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey,
    });
  }
  render() {
    const one = (
      <p>
        I am an <b>Experienced Software Engineer</b> with a strong foundation in
        building scalable, high-performance web applications. I hold a{" "}
        <b>Master of Science in Computer Science</b> from{" "}
        <a href="https://www.asu.edu/">Arizona State University</a>, where I
        gained advanced knowledge in Algorithms, Cloud Computing, and
        Optimization. My expertise spans modern web technologies like React.js,
        TypeScript, Node.js, and GraphQL, with a proven track record of
        delivering successful projects.
      </p>
    );

    const two = (
      <p>
        As a <b>Software Engineer</b>, I have contributed to various high-impact
        projects. At <a href="https://www.ringover.com/">Ringover</a>, I
        developed a sales prospecting tool using React.js and TypeScript,
        integrating multi-channel communication into a single interface. At{" "}
        <a href="https://dyte.io/">Dyte</a>, I built and maintained a seamless
        Unified Live Experience Platform, combining high-quality audio/video
        conferencing, live streaming, and chat into one SDK. Previously, I
        enhanced the recommendation engine at{" "}
        <a href="https://www.freshtohome.com/">FreshToHome</a> for over 1.5
        million active users. My experience includes working with cloud
        platforms like AWS, implementing real-time data solutions, and
        collaborating with cross-functional teams to deliver optimized and
        scalable software.
      </p>
    );

    const tech_stack = [
      "Typescript",
      "React.js",
      "JavaScript ES6+",
      "Node.js",
      "Python",
      "Redux",
    ];    

    return (
      <div id="about">
        <FadeInSection>
          <div className="section-header ">
            <span className="section-title">/ about me</span>
          </div>
          <div className="about-content">
            <div className="about-description">
              {[one]}
              {"Here are some technologies I have been working with:"}
              <ul className="tech-stack">
                {tech_stack.map(function (tech_item, i) {
                  return (
                    <FadeInSection delay={`${i + 1}00ms`}>
                      <li>{tech_item}</li>
                    </FadeInSection>
                  );
                })}
              </ul>
              {[two]}
            </div>
            <div className="about-image">
              <img alt="Madhav Rajesh" src={"/assets/me2.jpg"} />
            </div>
          </div>
        </FadeInSection>
      </div>
    );
  }
}

export default About;
